import { useAutoAnimate } from "@formkit/auto-animate/react";
import { BookmarkIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import Link from "components/Link";
import LoadingComponents from "components/Loader";
import { ListNavbar } from "components/Navbar";
import ListItem, { CheckBox } from "components/list/ListItem";
import { Color } from "context/ConfirmContextProvider";
import ListCXT from "context/ListContext";
import { addSavedItemsToList } from "db-utils";
import useAlert from "hooks/useAlert";
import useConfirm from "hooks/useConfirm";
import useListData from "hooks/useListData";
import useListIdFromParams from "hooks/useListIdFromParams";
import useUser from "hooks/useUser";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Item } from "types";
import { sortItemsByCategory } from "../../categories";
import { ADD_SAVED_USAGE_LIMIT, LONG_PRESS_VIBRATE } from "../../constants";
import { EditDrawer } from "./edit";
import { isPremium } from "utils";

export default function SavedItemsPage() {
    const { listId } = useListIdFromParams();
    const { listData, loading } = useListData({ listId });
    const { user, loading: userLoading } = useUser();
    const [listToShow, setListToShow] = useState<Array<Item>>([]);
    const [isListToShowLoading, setIsListToShowLoading] = useState(true);
    const { savedItems, items, categoriesSortOrder } = listData ?? {};
    const { isConfirmed } = useConfirm();
    const [selectedItems, setSelectedItems] = useState<Item[]>([]);
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const [itemIdToEdit, setItemIdToEdit] = useState<string | undefined>();
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [hideInListItems, setHideInListItems] = useState(false);

    const { isAlerted } = useAlert();

    useEffect(() => {
        if (!listData) return;

        if (!savedItems) {
            setIsListToShowLoading(false);
            return;
        }

        let sortedList = sortItemsByCategory({
            items: savedItems,
            categoriesSortOrder,
        });

        if (hideInListItems) {
            sortedList = sortedList.filter(
                (item) => !items?.map(({ text }) => text).includes(item.text)
            );
        }

        setListToShow(sortedList);
        setIsListToShowLoading(false);
    }, [savedItems, categoriesSortOrder, hideInListItems, items, listData]);

    const [parent] = useAutoAnimate({
        duration: 400,
    });

    const exisitingItemsIds = useMemo(
        () => listData?.items.map(({ text }) => text),
        [listData]
    );

    const selectedItemsIds = useMemo(
        () => selectedItems?.map(({ text }) => text),
        [selectedItems]
    );

    const { userId, userData } = user;

    const addSavedItems = useCallback(async () => {
        if (!items || !savedItems || !userId || !userData) return;
        const isUserPremium = isPremium({ type: userData.accountType });
        const { usage } = userData;
        // get YYYY-MM
        const currentMonthAndYear = new Date().toISOString().slice(0, 7);
        const numberOfUsageThisMonth =
            usage?.addSavedItems?.[currentMonthAndYear]?.count || 0;
        const isAllowedToUse =
            isUserPremium || numberOfUsageThisMonth < ADD_SAVED_USAGE_LIMIT;

        if (!isAllowedToUse) {
            isAlerted(
                `עברתם את כמות השימוש החינמי בפיצ'ר לחודש הזה.\nכדי להמשיך להשתמש בו תוכלו לשדרג למנוי פלוס ⚡ - מקבלים חודש ראשון בחינם לגמרי`
            );
        }

        if (
            await isConfirmed("להוסיף את הפריטים שבחרתם לרשימה?", Color.success)
        ) {
            await addSavedItemsToList({
                listId,
                userId,
                selectedSavedItems: selectedItems.filter(
                    (item) => !exisitingItemsIds?.includes(item.text)
                ), //! move this to the function's loginc itself
            });
            setSelectedItems([]);
        }
    }, [
        exisitingItemsIds,
        isAlerted,
        isConfirmed,
        items,
        listId,
        savedItems,
        selectedItems,
        userData,
        userId,
    ]);

    const hasSavedItemsInRealListAlready = useMemo(
        () =>
            items?.some((item) =>
                savedItems?.map(({ text }) => text).includes(item.text)
            ),
        [items, savedItems]
    );

    if (loading || userLoading) {
        return <LoadingComponents fullPage isList />;
    }

    if (!listData) return null;

    if (isListToShowLoading) {
        return <LoadingComponents fullPage isList />;
    }

    return (
        <ListCXT listId={listId}>
            <EditDrawer
                {...{ openEditDrawer, setOpenEditDrawer }}
                itemId={itemIdToEdit}
                isSavedItemsMode
            />
            <ListNavbar
                showActionsMenu={false}
                listName={listData.name + " - שמורים"}
                isSavedItems
            />
            <div className="mt-28 relative no-select">
                <div className="flex justify-between w-full gap-5 fixed top-0 right-0 mt-20 z-10 bg-white px-4 pb-3 lg:px-60">
                    <div className="flex justify-center gap-5 pr-3">
                        <button
                            onClick={() => {
                                if (!savedItems) {
                                    isAlerted("אין פריטים שמורים ברשימה");
                                    return;
                                }
                                setSelectedItems(
                                    isSelectedAll
                                        ? []
                                        : savedItems.filter(
                                              (item) =>
                                                  !exisitingItemsIds?.includes(
                                                      item.text
                                                  )
                                          )
                                );
                                setIsSelectedAll((prev) => !prev);
                            }}
                            className="flex items-center text-sm -gap-1"
                        >
                            <CheckBox checked={isSelectedAll} />
                            <span className="-mr-1">בחר הכל</span>
                        </button>
                        {hasSavedItemsInRealListAlready ? (
                            <button
                                className="flex items-center text-sm gap-1"
                                onClick={() =>
                                    setHideInListItems((prev) => !prev)
                                }
                            >
                                {hideInListItems ? (
                                    <PlusIcon className="h-5 w-5 text-slate-600" />
                                ) : (
                                    <MinusIcon className="h-5 w-5 text-slate-600" />
                                )}
                                <span className="text-sm">
                                    הצג {hideInListItems ? "יותר" : "פחות"}
                                </span>
                            </button>
                        ) : null}
                        {/* <button
                            onClick={() => setSelectedItems([])}
                            className="flex items-center text-sm"
                            disabled={selectedItems.length === 0}
                        >
                            <CheckBox />
                            <span className="-mr-1">נקה הכל</span>
                        </button> */}
                    </div>
                    <button
                        onClick={addSavedItems}
                        className="btn-primary h-10 w-[7.25rem] text-base"
                        disabled={selectedItems.length === 0}
                    >
                        הוסף לרשימה
                    </button>
                </div>
                <ul
                    ref={parent}
                    className="flex flex-col text-2xl divide-y pt-7 w-full pb-20"
                >
                    {listToShow && listToShow.length ? (
                        listToShow.map((listItem) => (
                            <Fragment key={listItem.text}>
                                <ListItem
                                    isSavedItemsMode
                                    isInListAlready={exisitingItemsIds?.includes(
                                        listItem.text
                                    )}
                                    {...listItem}
                                    onSavedItemSelect={({ itemId }) => {
                                        if (!savedItems) return;

                                        setSelectedItems((prev) => {
                                            const isSelectedAlready = prev
                                                .map(({ text }) => text)
                                                .includes(itemId);

                                            if (isSelectedAlready) {
                                                return prev.filter(
                                                    ({ text }) =>
                                                        text !== itemId
                                                );
                                            } else {
                                                return [
                                                    ...prev,
                                                    savedItems.find(
                                                        (item) =>
                                                            item.text === itemId
                                                    ) as Item,
                                                ];
                                            }
                                        });
                                        const isSelectedAlready =
                                            savedItems.find(
                                                (item) => item.text === itemId
                                            );
                                        if (isSelectedAlready) {
                                            setIsSelectedAll(false);
                                        }
                                    }}
                                    isSelectedSavedItem={selectedItemsIds.includes(
                                        listItem.text
                                    )}
                                    onItemLongPress={() => {
                                        if (navigator.vibrate) {
                                            navigator.vibrate(
                                                LONG_PRESS_VIBRATE
                                            );
                                        }
                                        setItemIdToEdit(listItem.text);
                                        setOpenEditDrawer(true);
                                    }}
                                    onEditClick={() => {
                                        setItemIdToEdit(listItem.text);
                                        setOpenEditDrawer(true);
                                    }}
                                />
                            </Fragment>
                        ))
                    ) : (
                        <div className="flex flex-col gap-12 text-2xl text-cetner items-center mt-10">
                            <h6 className="font-semibold">אין פריטים להציג</h6>
                            <p className="text-center text-xl text-slate-700 pt-2 leading-8 max-w-[400px] mx-auto">
                                כדי להוסיף פריט לרשימת ה"שמורים" יש לשמור אותו
                                מהרשימה הנוכחית שלכם, ע"י לחיצה על האייקון
                                <BookmarkIcon className="h-6 w-6 inline-block mx-1" />
                                במצב עריכה <br />
                                או שלחו לבוט
                                <span className="font-semibold mx-1">
                                    "שמור"
                                </span>
                                בצירוף שם הפריט
                            </p>
                            <button
                                className="btn-primary flex items-center gap-2 max-w-[400px] mx-auto"
                                onClick={async () => {
                                    await isAlerted(
                                        "אפשרות זו תהיה זמינה בקרוב"
                                    );
                                }}
                            >
                                הוספה ישירות מכאן
                                <PlusIcon className="h-5 w-5 text-white" />
                            </button>
                            <Link
                                to={`/list/${listId}/edit`}
                                className="underline text-green-500 text-[20px]"
                            >
                                הוספה מהרשימה הנוכחית
                            </Link>
                        </div>
                    )}
                </ul>
            </div>
        </ListCXT>
    );
}

import Link from "components/Link";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { db, TXS_ERRORS_COLLECTION } from "../firebase";

export default function FailedPaymentPage() {
    const [searchParams] = useSearchParams();
    const phone = searchParams.get("phone");

    useEffect(() => {
        addDoc(collection(db, TXS_ERRORS_COLLECTION), {
            phone: phone ?? "no phone",
            timestamp: Timestamp.now(),
        });
    }, [phone]);

    return (
        <div className="text-center flex flex-col gap-8 mt-10">
            <h1 className=" text-3xl font-semibold">
                אופס, נתקלנו בבעיה במהלך השדרוג...
            </h1>
            <p className="text-xl">כרטיסך לא חויב, כדאי לנסות שוב</p>
            <Link
                to={`/checkout?phone=${phone}&pay=1`}
                className="mt-5 btn-primary max-w-[400px] lg:mx-auto"
            >
                לנסות שוב
            </Link>
            <a
                className="mt-5 text-2xl flex underline items-center justify-center"
                href="https://wa.me/972587501168"
                target="_blank"
                rel="noopener noreferrer"
            >
                יצירת קשר
            </a>
        </div>
    );
}

import {
    ArrowLeftIcon,
    ArrowUturnRightIcon,
    BookmarkIcon,
    EllipsisVerticalIcon,
    PencilIcon,
    UserGroupIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import Drawer from "components/Drawer";
import Link from "components/Link";
import { Color } from "context/ConfirmContextProvider";
import {
    deleteAllCheckedItems,
    deleteAllItemsFromDB,
    markAllItemsAsNotMissing,
} from "db-utils";
import useAlert from "hooks/useAlert";
import useConfirm from "hooks/useConfirm";
import useList from "hooks/useList";
import useUser from "hooks/useUser";
import { useState } from "react";
import { isPremium } from "utils";
import splitIcon from "../../assets/images/split icon.png";
import TrashIcon from "../../components/icons/TrashIcon";

export default function ListActionsMenu({
    isEditing,
    show = true,
}: {
    isEditing?: boolean;
    show?: boolean;
}) {
    const { listData } = useList();
    const { user } = useUser();

    const [openMenu, setOpenMenu] = useState(false);

    const { isConfirmed } = useConfirm();
    const { isAlerted } = useAlert();

    if (!show) {
        // show only on list page
        return null;
    }

    if (!listData || !user || !user.userId) {
        // "ml" is needed when loading
        return (
            <div className="flex justift-end p-0">
                <EllipsisVerticalIcon className="text-slate-800 h-8 w-8" />
            </div>
        );
    }

    const { id: listId, items: listInDB } = listData;

    const isPremiumUser = isPremium({ type: user?.userData?.accountType });

    const buttons = [
        isEditing
            ? {
                  id: "back-to-list",
                  title: "חזרה לרשימה",
                  icon: (
                      <div
                          className={`text-slate-800 rounded-full flex items-center justify-center`}
                      >
                          <ArrowLeftIcon className="w-7 h-7" />
                      </div>
                  ),
                  link: `/list/${listId}/`,
              }
            : {
                  id: "edit-list",

                  title: "עריכת הרשימה",
                  icon: (
                      <div
                          className={`text-slate-800 rounded-full flex items-center justify-center`}
                      >
                          <PencilIcon className="w-7 h-7" />
                      </div>
                  ),
                  link: "edit",
              },
        // {
        //     title: "הוספת השמורים לרשימה",
        //     icon: (
        //         <BookmarkIcon className="w-7 h-7 group-disabled:text-gray-400 text-slate-800" />
        //     ),
        //     onClick: async () => {
        //         if (!isPremiumUser) {
        //             await isAlerted(
        //                 "אפשרות זו פתוחה רק למשתמשי Plus ⚡\nבואו תצטרפו לחיים הקלים דרך עמוד הPlus בתפריט"
        //             );
        //             setOpenMenu(false);
        //             return;
        //         }
        //         // if (await isConfirmed("להוסיף את כל הפריטים שמורים לרשימה?")) {
        //         await addSavedItemsToList({
        //             listId,
        //             userId: user.userId,
        //             selectedSavedItems: listData?.savedItems,
        //         });
        //         setOpenMenu(false);
        //         // }
        //     },
        //     link: undefined,
        // },
        {
            id: "saved-items-page",
            title: "פריטים קבועים ושמורים",
            // title: "פריטים קבועים",
            icon: (
                <BookmarkIcon className="w-7 h-7 group-disabled:text-gray-400 text-slate-800" />
            ),
            link: "saved-items",
        },
        {
            id: "add-missing-items",

            title: (
                <div>
                    החזר <span className="text-slate-400">חסרים</span> לרשימה
                </div>
            ),
            disabled: listInDB.every(({ missing }) => !missing),
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-7 h-7 group-disabled:text-gray-400 text-slate-800"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m15 15 6-6m0 0-6-6m6 6H9a6 6 0 0 0 0 12h3"
                    />
                </svg>
            ),
            onClick: async () => {
                if (
                    await isConfirmed(
                        "בטוח להחזיר את כל החסרים לרשימה?",
                        Color.success
                    )
                ) {
                    markAllItemsAsNotMissing({
                        listId,
                        existingItems: listInDB,
                    });
                }
            },
            link: undefined,
        },
        {
            id: "delete-checked-items",

            title: (
                <div>
                    מחק <span className="line-through">מסומנים</span>
                </div>
            ),
            disabled: listInDB.every(({ checked }) => !checked),
            icon: (
                <TrashIcon className="w-7 h-7 group-disabled:text-gray-400 text-red-500" />
            ),
            onClick: async () => {
                if (await isConfirmed("בטוח למחוק את כל המסומנים?")) {
                    deleteAllCheckedItems({
                        listId,
                        existingItems: listInDB,
                    });
                }
            },
            link: undefined,
        },
        {
            id: "delete-all-items",

            title: "מחק הכל",
            icon: (
                <TrashIcon className="w-7 h-7 group-disabled:text-gray-400 text-red-500" />
            ),
            onClick: async () => {
                if (await isConfirmed("בטוח למחוק את כל הרשימה?")) {
                    deleteAllItemsFromDB({ listId });
                }
            },
            link: undefined,
        },
    ];
    return (
        <>
            <button
                className="flex justift-end p-0"
                onClick={() => setOpenMenu(true)}
            >
                <EllipsisVerticalIcon className="text-slate-800 h-8 w-8" />
            </button>
            <Drawer
                open={openMenu}
                setOpen={setOpenMenu}
                onOpen={() => setOpenMenu(true)}
                onClose={() => setOpenMenu(false)}
                disableSwipeToOpen
            >
                <ul className="flex flex-col divide-y divide-slate-300">
                    {buttons.map(
                        ({ id, title, icon, disabled, onClick, link }) => {
                            const content = (
                                <button
                                    disabled={disabled}
                                    type="button"
                                    onClick={(e) => {
                                        onClick && onClick();
                                        setOpenMenu(false);
                                    }}
                                    className="flex group text-xl text-start justify-between w-full items-center px-2 py-2"
                                >
                                    <div>{title}</div>
                                    {icon}
                                </button>
                            );

                            return (
                                <li
                                    key={id}
                                    className="flex justify-between w-full items-center px-2 py-3"
                                >
                                    {link ? (
                                        <Link to={link} className="w-full">
                                            {content}
                                        </Link>
                                    ) : (
                                        content
                                    )}
                                </li>
                            );
                        }
                    )}
                </ul>
            </Drawer>
        </>
    );
}

export function ListSettingsMenu({
    openMenu,
    setOpenMenu,
}: {
    openMenu: boolean;
    setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { listData } = useList();
    const { user } = useUser();

    const { isConfirmed } = useConfirm();
    const { isAlerted } = useAlert();

    // const [openMenu, setOpenMenu] = useState(false);

    if (!listData || !user) {
        return null;
        // return (
        //     <div className="flex justift-end">
        //         <AdjustmentsVerticalIcon className="text-slate-800 p-1 h-10 w-10 stroke-[1.5]" />
        //     </div>
        // );
    }
    const { id: listId, splitMode, items: listInDB } = listData;

    const buttons = [
        // {
        //     title: `צפייה ברשימה${splitMode ? " המלאה" : ""}`,
        //     icon: (
        //         <svg
        //             xmlns="http://www.w3.org/2000/svg"
        //             fill="none"
        //             viewBox="0 0 24 24"
        //             strokeWidth={1.5}
        //             stroke="currentColor"
        //             className="w-7 h-7"
        //         >
        //             <path
        //                 strokeLinecap="round"
        //                 strokeLinejoin="round"
        //                 d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
        //             />
        //         </svg>
        //     ),
        //     onClick: () => {},
        //     link: `/list/${listId}/`,
        // },

        {
            id: "delete-all-checked",

            title: (
                <div>
                    מחק <span className="line-through">מסומנים</span>
                </div>
            ),
            disabled: listInDB.every(({ checked }) => !checked),
            icon: (
                <TrashIcon className="w-7 h-7 group-disabled:text-gray-400 text-red-500" />
            ),
            onClick: async () => {
                if (await isConfirmed("בטוח למחוק את כל המסומנים?")) {
                    deleteAllCheckedItems({
                        listId,
                        existingItems: listInDB,
                    });
                }
            },
            link: undefined,
        },
        {
            id: "delete-all",

            title: "מחק הכל",
            icon: (
                <TrashIcon className="w-7 h-7 group-disabled:text-gray-400 text-red-500" />
            ),
            onClick: async () => {
                if (await isConfirmed("בטוח למחוק את כל הרשימה?")) {
                    deleteAllItemsFromDB({ listId });
                }
            },
            link: undefined,
        },

        {
            id: "add-missing-items",
            title: (
                <div>
                    החזר <span className="text-slate-400">חסרים</span> לרשימה
                </div>
            ),
            disabled: listInDB.every(({ missing }) => !missing),
            icon: (
                <ArrowUturnRightIcon className="w-7 h-7 group-disabled:text-gray-200 text-slate-800" />
            ),
            onClick: async () => {
                if (
                    await isConfirmed(
                        "בטוח להחזיר את כל החסרים לרשימה?",
                        Color.success
                    )
                ) {
                    markAllItemsAsNotMissing({
                        listId,
                        existingItems: listInDB,
                    });
                }
            },
            link: undefined,
        },
        {
            id: "cats-order",

            title: "סדר קטגוריות",
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-7 h-7 text-slate-800 group-disabled:text-gray-400"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                    />
                </svg>
            ),
            onClick: () => {},
            link: `/list/${listId}/category-order/`,
        },
        {
            id: "split-list",
            title: "פיצול הרשימה",
            icon: <img src={splitIcon} alt="split" className="w-7 h-7 p-0.5" />,
            onClick: () => {},
            link: `/list/${listId}/split/`,
        },
        {
            id: "whos-in-list",
            title: "ניהול משתתפים",
            icon: (
                <UserGroupIcon className="w-7 h-7 group-disabled:text-gray-400 text-slate-800" />
            ),
            onClick: () => {},
            link: `/list/${listId}/participants/`,
        },
        {
            id: "close-menu",

            title: "סגירת התפריט",
            icon: (
                <XMarkIcon className="w-7 h-7 group-disabled:text-gray-400 text-slate-800" />
            ),
            onClick: async () => {},
            link: undefined,
        },
    ];

    if (splitMode) {
        buttons.unshift({
            id: "view-split-list",
            title: "צפייה ברשימה המפוצלת",
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-7 h-7"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                    />
                </svg>
            ),
            onClick: () => {},
            link: `/list/${listId}/?split=true`,
        });
    }
    return (
        <>
            {/* <button
                className="flex justift-end p-0"
                onClick={() => setOpenMenu(true)}
            >
                <AdjustmentsVerticalIcon className="text-slate-800 p-1 h-10 w-10 stroke-[1.5]" />
            </button> */}
            <Drawer
                open={openMenu}
                setOpen={setOpenMenu}
                onOpen={() => setOpenMenu(true)}
                onClose={() => setOpenMenu(false)}
                disableSwipeToOpen={true}
            >
                <ul className="flex flex-col divide-y divide-slate-300">
                    {buttons.map(
                        ({ id, title, icon, onClick, link, disabled }) => {
                            const content = (
                                <button
                                    type="button"
                                    disabled={disabled}
                                    onClick={(e) => {
                                        onClick && onClick();
                                        setOpenMenu(false);
                                    }}
                                    className="flex group text-xl text-start justify-between w-full items-center px-2 py-2"
                                >
                                    <div>{title}</div>
                                    {icon}
                                </button>
                            );

                            return (
                                <li
                                    key={id}
                                    className="flex justify-between w-full items-center px-2 py-3"
                                >
                                    {link ? (
                                        <Link to={link} className="w-full">
                                            {content}
                                        </Link>
                                    ) : (
                                        content
                                    )}
                                </li>
                            );
                        }
                    )}
                </ul>
            </Drawer>
        </>
    );
}

import { ReactNode, createContext, useState } from "react";

export interface Alert {
    prompt: string;
    isOpen: boolean;
    proceed: () => void;
    cancel: () => void;
}

const initialState: Alert = {
    prompt: "",
    isOpen: false,
    proceed: () => {},
    cancel: () => {},
};

export const AlertContext = createContext<
    [Alert, React.Dispatch<React.SetStateAction<Alert>>]
>([initialState, () => {}]);

interface AlertContextProviderProps {
    children: ReactNode;
}

const AlertContextProvider: React.FC<AlertContextProviderProps> = ({
    children,
}) => {
    const [alert, setAlert] = useState<Alert>(initialState);

    return (
        <AlertContext.Provider value={[alert, setAlert]}>
            {children}
        </AlertContext.Provider>
    );
};

export default AlertContextProvider;

import React, { useMemo } from "react";
import { useLocation } from "react-router";
import Footer from "./Footer";
import Navbar from "./Navbar";

export default function Layout({ children }: { children: React.ReactNode }) {
    const location = useLocation();
    const isPremiumInfoPage = useMemo(
        () => location.pathname.includes("/premium-info"),
        [location]
    );

    return (
        <div className="overflow-hidden">
            <Navbar />
            <main
                dir="rtl"
                // style={{
                //     background:
                //         "conic-gradient(from 119deg at -19% 36%, transparent 50%, #9ADE7B 55%, transparent 92%)",
                // }}
                className={`min-h-screen font-assistant bg-white ${
                    // className={`h-full pt-20 font-assistant min-h-[90vh] bg-white ${
                    isPremiumInfoPage ? "" : "px-4 lg:px-60"
                } `}
            >
                {children}
            </main>
            <Footer />
        </div>
    );
}

import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ListData } from "types";
import { LISTS_COLLECTION, db } from "../firebase";
import useUser from "./useUser";

export type ListDataExtended = ListData & { id: string };

export default function useListData({ listId }: { listId: string }) {
    const [listData, setListData] = useState<ListData | undefined | null>(
        undefined
    );
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | undefined>();
    const { user } = useUser();

    useEffect(() => {
        try {
            if (!listId || !user || user.userData === undefined) return;

            const unsubscribe = onSnapshot(
                doc(db, LISTS_COLLECTION, listId),
                (doc) => {
                    setListData(doc.data() as ListData);
                    setLoading(false);
                    setError(undefined);
                },
                (error) => {
                    console.error(error, user);
                    setError("No user or listId");
                    setListData(undefined);
                    setLoading(false);
                }
            );

            return () => unsubscribe();
        } catch (e) {
            console.error(e, user, "catch 2");
            setError("No user or listId");
            setListData(undefined);
            setLoading(false);
        }
    }, [listId, user]);

    return {
        listData: listData
            ? ({
                  ...listData,
                  id: listId,
              } as ListDataExtended)
            : undefined,
        loading,
        error,
    };

    // const [valueRaw, loadingRaw, errorRaw] = useDocumentData<DocumentData>(
    //     doc(db, LISTS_COLLECTION, listId)
    // );

    // const data = useMemo(() => {
    //     return {
    //         value: valueRaw,
    //         loading: loadingRaw,
    //         error: errorRaw,
    //     };
    // }, [valueRaw, loadingRaw, errorRaw]);

    // const { value, loading, error } = data;

    // console.log("useListData", value, loading, error);
    // if (!value) return { loading, error };

    // // const { user } = useUser();
    // // if (!value || !user) return { loading, error };

    // return {
    //     listData: value
    //         ? ({
    //               ...value,
    //               id: listId,
    //           } as ListDataExtended)
    //         : undefined,
    //     loading,
    //     error,
    // };
}

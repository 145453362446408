import AlertModal from "components/AlertModal";
import ConfirmModal from "components/ConfirmModal";
import AlertContextProvider from "context/AlertContextProvider";
import ConfirmContextProvider from "context/ConfirmContextProvider";
import UserCXT from "context/UserContext";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Accessibility from "routes/accessibility";
import Account from "routes/account";
import CheckoutPage from "routes/checkout";
import FailedPaymentPage from "routes/failed-payment";
import IsChatSolutions from "routes/is-chat-solutions";
import CategoriesOrderPage from "routes/list/category-order";
import EditListPage from "routes/list/edit";
import ListParticipantsPage from "routes/list/participants";
import SavedItemsPage from "routes/list/saved-items";
import SplitPage from "routes/list/split";
import ListsPage from "routes/lists";
import SuccessPaymentPage from "routes/success-payment";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import NotFoundPage from "./routes/404";
import Home from "./routes/Home";
import Root from "./routes/Root";
import InviteToJoin from "./routes/invite-to-list";
import Join from "./routes/join";
import ListPage from "./routes/list";
import PremiumInfo from "./routes/premium-info";
import Privacy from "./routes/privacy";
import Share, { loader as shareLoader } from "./routes/share";
import TermsOfService from "./routes/terms-of-service";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <NotFoundPage />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/lists/",
                element: (
                    <UserCXT>
                        <ListsPage />
                    </UserCXT>
                ),
            },
            {
                path: "/list/:id",
                element: (
                    <UserCXT>
                        <ListPage />
                    </UserCXT>
                ),
            },
            {
                path: "/list/:id/edit",
                element: (
                    <UserCXT>
                        <EditListPage />
                    </UserCXT>
                ),
            },
            {
                path: "/list/:id/saved-items",
                element: (
                    <UserCXT>
                        <SavedItemsPage />
                    </UserCXT>
                ),
            },
            {
                path: "/list/:id/split",
                element: (
                    <UserCXT>
                        <SplitPage />
                    </UserCXT>
                ),
            },
            {
                path: "/list/:id/participants",
                element: (
                    <UserCXT>
                        <ListParticipantsPage />
                    </UserCXT>
                ),
            },
            {
                path: "/list/:id/category-order",
                element: (
                    <UserCXT>
                        <CategoriesOrderPage />
                    </UserCXT>
                ),
            },
            {
                path: "/account",
                element: (
                    <UserCXT>
                        <Account />
                    </UserCXT>
                ),
            },
            {
                path: "/join",
                element: <Join />,
            },
            {
                path: "/join/:listId",
                element: <Join />,
            },
            {
                path: "/share/:senderPhoneNumber",
                element: <Share />,
                loader: shareLoader,
            },
            {
                path: "/share/",
                element: <Share />,
                loader: shareLoader,
            },
            {
                path: "/invite-to-list/",
                element: <InviteToJoin />,
            },
            {
                path: "/privacy",
                element: <Privacy />,
            },
            {
                path: "/accessibility",
                element: <Accessibility />,
            },
            {
                path: "/terms-of-service",
                element: <TermsOfService />,
            },
            {
                path: "premium-info",
                element: <PremiumInfo />,
            },
            {
                path: "checkout",
                element: <CheckoutPage />,
            },
            {
                path: "/success-payment",
                element: <SuccessPaymentPage />,
            },
            {
                path: "/failed-payment",
                element: <FailedPaymentPage />,
            },
            {
                path: "is-chat-solutions",
                element: <IsChatSolutions />,
            },
        ],
    },
]);

root.render(
    <React.StrictMode>
        <ConfirmContextProvider>
            <AlertContextProvider>
                <RouterProvider router={router} />
                <ConfirmModal />
                <AlertModal />
            </AlertContextProvider>
        </ConfirmContextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import Link from "components/Link";

export default function NotFoundPage() {
    return (
        <div className="font-poppins flex flex-col items-center gap-2 justify-center h-screen w-screnn">
            <div className="flex items-center text-xl gap-2 justify-center mb-3">
                <h1>404</h1>
                <span>|</span>
                <p>Page not found</p>
            </div>
            <Link
                outsideUserContext
                to="/"
                className="px-3 py-2 w-40 text-lg block text-center rounded-lg bg-green-500 text-white hover:bg-green-600 shadow-md transition-all hover:-translate-y-1 hover:shadow-xl"
            >
                Back to Home
            </Link>
        </div>
    );
}

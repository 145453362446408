import { Menu, Transition } from "@headlessui/react";
import { CATEGORIES } from "categories";
import { Fragment } from "react";

export default function CategoriesDropdown({
    onSelect,
    button,
}: {
    onSelect: (categoryId: string) => void;
    button: React.ReactNode;
}) {
    return (
        <Menu className="relative" as="div">
            <Menu.Button>{button}</Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="overflow-auto absolute p-2 -right-56 mt-1 h-96 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none flex flex-col z-40">
                    {Object.values(CATEGORIES).map((category) => (
                        <Menu.Item key={category.id}>
                            {({ active }) => (
                                <button
                                    type="button"
                                    onClick={() => {
                                        onSelect(category.id);
                                    }}
                                    className="flex text-2xl items-center gap-3 w-full py-3"
                                >
                                    {category.icon}
                                    <span>{category.title}</span>
                                </button>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}

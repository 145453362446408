import { AlertContext } from "context/AlertContextProvider";
import { useContext, useEffect, useState } from "react";
import { Confirm } from "../context/ConfirmContextProvider";

interface UseAlertReturn extends Confirm {
    isAlerted: (prompt: string) => Promise<boolean>;
}

const useAlert = (): UseAlertReturn => {
    const [alert, setAlert] = useContext(AlertContext);
    const [needsCleanup, setNeedsCleanup] = useState(false);

    const isAlerted = (prompt: string): Promise<boolean> => {
        const promise = new Promise<boolean>((resolve, reject) => {
            setAlert({
                prompt,
                isOpen: true,
                proceed: () => resolve(true),
                cancel: () => reject(false),
            });
            setNeedsCleanup(true);
        });

        const reset = () => {
            setAlert({
                prompt: "",
                proceed: () => {},
                cancel: () => {},
                isOpen: false,
            });
            setNeedsCleanup(false);
        };

        return promise.then(
            () => {
                reset();
                return true;
            },
            () => {
                reset();
                return false;
            }
        );
    };

    // Call cancel in a cleanup func to avoid dangling confirm dialog
    useEffect(() => {
        return () => {
            if (alert.cancel && needsCleanup) {
                alert.cancel();
            }
        };
    }, [alert, needsCleanup]);

    return {
        ...alert,
        isAlerted,
    };
};

export default useAlert;

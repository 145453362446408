import Link from "./Link";

export default function Footer() {
    return (
        <footer className="font-assistant bg-white relative z-10 flex flex-col gap-2 h-16 py-3 items-center justify-center ">
            <div className="flex flex-row-reverse gap-5 lg:gap-10 items-center justify-between text-sm">
                <a
                    className="underline"
                    href="https://wa.me/972587501168"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    דברו איתנו
                </a>
                <Link outsideUserContext to="/privacy" className="">
                    מדיניות פרטיות
                </Link>
                <Link outsideUserContext to="/terms-of-service" className="">
                    תנאי שימוש
                </Link>
                <Link outsideUserContext to="/accessibility" className="">
                    הצהרת נגישות
                </Link>
            </div>
            <div className="flex gap-1 font-poppins items-center justify-center pb-2 text-xs lg:text-sm">
                Groceroo {new Date().getFullYear()} &copy; All Rights Reserved
            </div>
        </footer>
    );
}

import logoWhite from "../assets/images/groceroo-logo-white.svg";
import logo from "../assets/images/groceroo-logo.svg";

export default function Logo({ white }: { white?: boolean }) {
    return (
        <img
            src={white ? logoWhite : logo}
            alt="Groceroo logo"
            className="h-10"
        />
    );
}

import ReactConfetti from "react-confetti";
import { useSearchParams } from "react-router-dom";
import { useAsync } from "react-use";
import Loader from "../components/Loader";
import { BOT_PHONE_NUMBER } from "../constants";

export default function SuccessPaymentPage() {
    const [searchParams] = useSearchParams();
    const txId = searchParams.get("txId");
    let confirmationNumber = searchParams.get("confirmationNumber");
    const { value, loading, error } = useAsync(async () => {
        try {
            if (!txId) {
                return false;
            } else {
                return true;
            }
            // const res = await verifyPayment({
            //     txId,
            // });

            // if (res && res.data) {
            //     const { status } = res.data as { status: number };
            //     if (status === 200) {
            //         console.log("Payment successful");
            //         return true;
            //     } else {
            //         console.log("Payment failed");
            //         return false;
            //     }
            // } else {
            //     console.log("Payment failed");
            //     return false;
            // }
        } catch (err) {
            console.error(err);
            return false;
        }
    }, []);

    if (loading) {
        return <Loader fullPage />;
    }

    confirmationNumber =
        confirmationNumber === "null" ? null : confirmationNumber;
    return (
        <div>
            {value === true ? (
                <div className="py-10 px-5 flex flex-col gap-8">
                    <ReactConfetti
                        className="opacity-30"
                        gravity={0.25}
                        numberOfPieces={500}
                    />
                    <h1 className="text-center text-3xl font-semibold">
                        שודרגתם! 😍
                    </h1>
                    <p className="text-lg">
                        אתם יכולים להתחיל להשתמש בכל הפיצ'רים החדשים.
                        <br /> שלחו לבוט
                        <span className="mx-1 font-semibold">
                            "פקודות פלוס"
                        </span>
                        ותוכלו לראות איך משתמשים בפקודות החדשות שנפתחו בפניכם.
                    </p>
                    <a
                        href={encodeURI(
                            `https://wa.me/${BOT_PHONE_NUMBER}?text=פקודות פלוס`
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative z-10 mt-5 btn-primary max-w-[400px] lg:mx-auto"
                    >
                        מעבר לבוט
                    </a>
                    {confirmationNumber ? (
                        <span className="text-center">
                            מספר אישור: {confirmationNumber}
                        </span>
                    ) : null}
                </div>
            ) : (
                <div className="text-center flex flex-col gap-8 mt-10">
                    <h1 className=" text-3xl font-semibold">
                        אופס, נתקלנו בבעיה במהלך השדרוג...
                    </h1>
                    <p className="text-xl">
                        אבל אל דאגה,
                        <br />
                        דברו איתנו ונפתור את הבעיה.
                    </p>
                    <a
                        className="mt-5 text-2xl font-semibold w-full gap-2 bg-green-500 text-white disabled:bg-slate-200 rounded-lg shadow-sm px-3 py-3 flex items-center justify-center max-w-[400px] lg:mx-auto"
                        href="https://wa.me/972587501168"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        דברו איתנו
                    </a>
                </div>
            )}
        </div>
    );
}

import { UserContext } from "context/UserContext";
import { useContext, useMemo } from "react";
import { UserData } from "types";

export default function useUser() {
    const { user: userRaw, loading: loadingRaw } = useContext(UserContext);

    const userId = userRaw?.userId;

    const userData = userRaw?.userData;

    const user = useMemo(() => ({ userId, userData }), [userId, userData]);

    const loading = useMemo(() => loadingRaw, [loadingRaw]);

    if (loading)
        return {
            user: {
                userId: null,
                userData: undefined,
            },
            loading,
        };

    if (!userId || !userData) {
        throw new Error("useUser: user is undefined");
    }

    return {
        user: user as { userId: string; userData: UserData },
        loading,
    };
}

import React from "react";
// import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";

export default function Link({
    to,
    className,
    children,
    onClick,
    disabled = false,
    outsideUserContext = false,
}: {
    to?: string;
    className?: string;
    children: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    outsideUserContext?: boolean;
}) {
    if (!to || disabled) {
        return (
            <button className={className} onClick={onClick} disabled={disabled}>
                {children}
            </button>
        );
    }

    if (outsideUserContext) {
        return (
            <a
                href={to}
                className={`${className} cursor-pointer`}
                onClick={onClick}
            >
                {children}
            </a>
        );
    }

    return (
        <RouterLink
            to={to}
            className={className}
            children={children}
            onClick={onClick}
        />
    );

    // const navigate = useNavigate();
    // return (
    //     <div
    //         onClick={() => {
    //             onClick && onClick();
    //             navigate(to);
    //         }}
    //         className={`${className} cursor-pointer`}
    //     >
    //         {children}
    //     </div>
    // );
}

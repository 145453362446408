import { getListData } from "db-utils";
import { useAsync } from "react-use";
import { ListData } from "types";
import useUser from "./useUser";

export default function useListsData() {
    const { user } = useUser();

    const { value, loading, error } = useAsync(async () => {
        if (!user) return;
        const { userData } = user;
        if (!userData) return;
        const { lists } = userData;
        const listsData = await Promise.all(
            lists.map(async (listId) => {
                const listData = await getListData({ listId });
                return { ...listData, listId } as ListData & {
                    listId: string;
                };
            })
        );
        return listsData;
    }, [user]);

    return { listsData: value, loading, error };
}

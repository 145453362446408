import { ListContext } from "context/ListContext";
import { useContext } from "react";

export default function useList() {
    const ctx = useContext(ListContext);

    if (!ctx) {
        throw new Error("useList must be used within a ListContext.Provider");
    }

    const { listData, loading, error } = ctx;
    return { listData, loading, error };
}

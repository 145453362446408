import { useEffect, useState } from "react";
import { useSearchParam } from "react-use";

export default function useGrocerooToken() {
    const paramsToken = useSearchParam("t");
    const [token, setToken] = useState<string | undefined | null>();

    useEffect(() => {
        const localToken = localStorage.getItem("groceroo-token");
        if (paramsToken) {
            setToken(paramsToken);
            localStorage.setItem("groceroo-token", paramsToken);
        } else if (localToken) {
            setToken(localToken);
        } else {
            setToken(null);
        }
    }, [paramsToken]);

    return token;
}

import React from "react";
import { v4 as uuid } from "uuid";

export default function useUserId({
    phoneNumber,
}: {
    phoneNumber?: string | null;
}) {
    // gey user ID from local storage or random ID
    const [userId, setUserId] = React.useState(
        localStorage.getItem("userId") ?? phoneNumber ?? uuid()
    );

    // update user ID in local storage
    React.useEffect(() => {
        localStorage.setItem("userId", userId);
    }, [userId]);

    return { userId };
}

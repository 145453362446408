import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useEffect, useMemo, useState } from "react";

import { sortItemsByCategory } from "categories";
import LoadingComponents from "components/Loader";
import { ListNavbar } from "components/Navbar";
import AddItemPopup from "components/list/AddItemPopup";
import ListItem from "components/list/ListItem";
import ListCXT from "context/ListContext";
// import { useFirebaseRoutesAnalytics } from "hooks/useFirebaseAnalytics";
import useListData from "hooks/useListData";
import useListIdFromParams from "hooks/useListIdFromParams";
import useUser from "hooks/useUser";
import ReactConfetti from "react-confetti";
import { useSearchParams } from "react-router-dom";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Item } from "types";
import { EditDrawer } from "./edit";

export default function ListPage() {
    const { listId } = useListIdFromParams();
    const [searchParams] = useSearchParams();
    const showInSplitMode = searchParams.get("split");
    const { user, loading: userLoading } = useUser();
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [itemIdToEdit, setItemIdToEdit] = useState<string | undefined>();

    // useFirebaseRoutesAnalytics({ listId });
    const { listData, loading, error } = useListData({ listId });

    const [listToShow, setListToShow] = useState<Array<Item>>();
    const [isListToShowLoading, setListToShowLoading] = useState(true);
    const {
        items: listInDB,
        categoriesSortOrder,
        splitMode,
        partialListsConfig,
    } = listData ?? {};

    const [parent] = useAutoAnimate({
        duration:
            listToShow?.length ?? 0 <= 15
                ? 400
                : listToShow?.length ?? 0 <= 30
                ? 600
                : 1300,
    });

    const userId = user?.userId;

    useEffect(() => {
        if (!listInDB) return;

        const sortedList = sortItemsByCategory({
            items: listInDB,
            categoriesSortOrder,
        });

        const listWithMissingAtBottom = sortedList.toSorted(
            (firstItem: Item, secondItem: Item) => {
                const { missing: a } = firstItem;
                const { missing: b } = secondItem;
                if (a && !b) return 1;
                if (!a && b) return -1;
                return 0;
            }
        );

        const listWithMarkedAtBottom = listWithMissingAtBottom.toSorted(
            (firstItem: Item, secondItem: Item) => {
                const { checked: a } = firstItem;
                const { checked: b } = secondItem;
                if (a && !b) return 1;
                if (!a && b) return -1;
                return 0;
            }
        );

        const showSplitMode =
            partialListsConfig && splitMode && showInSplitMode;

        if (showSplitMode) {
            const partialList = partialListsConfig?.find((partialList) =>
                partialList.assignees.includes(userId!)
            );

            const currentPartList = listWithMarkedAtBottom.filter(
                (item) =>
                    partialList?.categories.includes(item.categoryId) ?? false
            );

            setListToShow(currentPartList);
        } else {
            setListToShow(listWithMarkedAtBottom);
        }
        setListToShowLoading(false);
    }, [
        listInDB,
        categoriesSortOrder,
        partialListsConfig,
        splitMode,
        showInSplitMode,
        userId,
    ]);

    const listProgress = useMemo(
        () =>
            listInDB
                ? listInDB.filter((item) => item.checked || item.missing)
                      .length / listInDB.length
                : 0,
        [listInDB]
    );

    if (loading || userLoading) {
        return <LoadingComponents fullPage isList />;
    }

    if (error || !listData || !listInDB)
        return (
            <div className="flex items-center justify-center flex-col gap-5 mt-16">
                <h1 className="text-3xl font-medium">לא נמצאה רשימה</h1>
                <p className="text-lg">יש להיכנס דרך הקישור שקיבלתם בוואטסאפ</p>
            </div>
        );

    if (!userId) {
        return (
            <div className="flex pt-20 mt-16 flex-col gap-5 items-center justify-center h-full text-center text-3xl">
                <h1>לא נמצא משתמש</h1>
                <div className="text-xl">
                    יש להיכנס דרך הקישור שקיבלתם בוואטסאפ
                </div>
            </div>
        );
    }

    if (isListToShowLoading) {
        return <LoadingComponents fullPage isList />;
    }

    return (
        <ListCXT listId={listId}>
            <EditDrawer
                {...{ openEditDrawer, setOpenEditDrawer }}
                itemId={itemIdToEdit}
            />
            <div className="h-full relative mt-16 no-select">
                {showConfetti ? (
                    <ReactConfetti
                        gravity={0.13}
                        initialVelocityY={11}
                        numberOfPieces={800}
                        recycle={false}
                        opacity={0.8}
                        onConfettiComplete={() => setShowConfetti(false)}
                        tweenDuration={12000}
                    />
                ) : null}
                <ListNavbar
                    listProgress={listProgress}
                    listName={listData.name}
                    showSplitMode={Boolean(showInSplitMode)}
                />
                <div className="fixed bottom-20 right-0 pr-4 pl-2 z-20 w-full">
                    <AddItemPopup />
                </div>
                <ul
                    ref={parent}
                    className="flex flex-col divide-y divide-slate-100 text-2xl pt-10 w-full pb-20"
                >
                    {listToShow && listToShow.length ? (
                        listToShow.map((listItem) => (
                            <ListItem
                                key={listItem.text}
                                {...listItem}
                                onEditClick={() => {
                                    setItemIdToEdit(listItem.text);
                                    setOpenEditDrawer(true);
                                }}
                                setShowConfetti={setShowConfetti}
                                onItemLongPress={() => {
                                    setItemIdToEdit(listItem.text);
                                    setOpenEditDrawer(true);
                                }}
                            />
                        ))
                    ) : (
                        <div className="grid place-content-center text-2xl mt-10">
                            אין פריטים להציג
                        </div>
                    )}
                </ul>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={4000}
                // autoClose={false}
                hideProgressBar={true}
                // hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                limit={3}
                closeButton={() => null}
                transition={Bounce}
                draggablePercent={33}
                style={{ bottom: "64px" }} // 64px for the bottom navbar
                className="md:!w-1/2"
            />
        </ListCXT>
    );
}

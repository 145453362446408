import { ReactNode, createContext, useState } from "react";

export enum Color {
    primary = "primary",
    secondary = "secondary",
    success = "success",
    danger = "danger",
    warning = "warning",
    info = "info",
    light = "light",
    dark = "dark",
}

export interface Confirm {
    prompt: string;
    isOpen: boolean;
    proceed: () => void;
    cancel: () => void;
    color?: Color;
}

const initialState: Confirm = {
    prompt: "",
    isOpen: false,
    proceed: () => {},
    cancel: () => {},
    color: Color.warning,
};

export const ConfirmContext = createContext<
    [Confirm, React.Dispatch<React.SetStateAction<Confirm>>]
>([initialState, () => {}]);

interface ConfirmContextProviderProps {
    children: ReactNode;
}

const ConfirmContextProvider: React.FC<ConfirmContextProviderProps> = ({
    children,
}) => {
    const [confirm, setConfirm] = useState<Confirm>(initialState);

    return (
        <ConfirmContext.Provider value={[confirm, setConfirm]}>
            {children}
        </ConfirmContext.Provider>
    );
};

export default ConfirmContextProvider;

import Helmet from "react-helmet";

export default function IsChatSolutions() {
    return (
        <div>
            <Helmet>
                <title>IS Chat Solutions</title>
                <meta name="description" content="IS Chat Solutions" />
            </Helmet>
            <h1>IS Chat Solutions</h1>
        </div>
    );
}
